@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  background-color: #0D0E0F;
} 

/* poppins */

@font-face {
  font-family: poppins;
  src: url(../public/fonts/poppins/Poppins-Light.ttf);                
  font-weight: 300;
}

@font-face {
  font-family: poppins;
  src: url(../public/fonts/poppins/Poppins-Regular.ttf);                
  font-weight: 400;
}

@font-face {
  font-family: poppins;
  src: url(../public/fonts/poppins/Poppins-Medium.ttf);                
  font-weight: 500;
}

@font-face {
  font-family: poppins;
  src: url(../public/fonts/poppins/Poppins-SemiBold.ttf);                
  font-weight: 600;
}

@font-face {
  font-family: poppins;
  src: url(../public/fonts/poppins/Poppins-Bold.ttf);                
  font-weight: 700;
}

.poppins {
  font-family: poppins;
}

/* inter */

@font-face {
  font-family: 'Inter', sans-serif;
  src: url(../public/fonts/inter/Inter-Light.ttf);                
  font-weight: 300;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url(../public/fonts/inter/Inter-Regular.ttf);                
  font-weight: 400;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url(../public/fonts/inter/Inter-Medium.ttf);                
  font-weight: 500;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url(../public/fonts/inter/Inter-SemiBold.ttf);                
  font-weight: 600;
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url(../public/fonts/inter/Inter-Bold.ttf);                
  font-weight: 700;
}

.inter {
  font-family: 'Inter', sans-serif;
}

// END FONTS


.energy_bg {
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
}

.app_shadow {
    box-shadow: 0px 63px 40px -36px rgba(0, 0, 0, 0.45);
    @media (max-width: 767px) {
        box-shadow: none;
    }
}

  
  .container {
    height: 44px;
  }
  
  .barCompleted {
    display: flex;
    align-items: center;
    padding-inline: 20px;
    background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    height: 100%;
    border-radius: 40px;
  }
  
  .label {
    font-size: 20px;
    color: white;
    font-weight: 500;
  }


  .space_ambassador_bg {
    background: linear-gradient(167deg, #3F8CFF -1.35%, #51CBFF 132.91%), #121314;
  }

  .coin_market_cap {
    background: linear-gradient(134deg, #75D6FF -24.01%, #2663FF 159.62%);
  }

  .notify-styles {
    color: white !important;
    border-radius: 16px !important;
    border: 1px solid rgba(255, 255, 255, 0.10) !important;
    background: linear-gradient(98deg, rgba(40, 40, 40, 0.50) 6.79%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60) !important;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.35) !important;
  }

  .qornex-banner-bg {
    background-image: url('../public/mainPage/ProjectList/qornex/dashboard.png');
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 991px) {
      background-image: url('../public/mainPage/ProjectList/qornex/dashboardMob.png');
      background-size: contain;
    }
  }

  .slick-prev:before {
    display: none;
  }

  .slick-slide {
    padding-left: 20px !important;
    @media (max-width: 991px) {
      padding-left: 0px;
    }
  }

  .slick-next:before {
    content: url('../public/mainPage/ProjectList/arrow.png');
    color: red;
    width: 20px;
    height: 20px;
    position: absolute;
  }

  button, a {
    &:hover {
      opacity: 0.75;
    }
  }